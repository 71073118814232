<template>
  <div v-if="!!this.detail.executionDesc" class="campaigns-card">
    <div class="create-time flex-btw">
      <span>执行时间:{{ execTime }}</span>
    </div>
    <van-cell title="提示">
      <template #label>
        <div :class="getColor" v-html="executionDesc" />
      </template>
    </van-cell>
  </div>
</template>
<script>
import alertColor from './alertColor'
export default {
  props: {
    detail: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  computed: {
    executionDesc() {
      if (!this.detail.executionDesc) {
        return "";
      }
      if (!this.detail.executionDesc.includes("执行明细")) {
        return this.detail.executionDesc;
      }
      let text = this.detail.executionDesc.substr(
        0,
        this.detail.executionDesc.length - 1
      );
      return text
        .replace("(执行明细", "<br/>执行明细")
        .replace(/\-\>/g, "<br/>");
    },
    execTime() {
      return this.detail.execTime
    },
    getColor() {
      let color = '';
      for (let key in alertColor) {
        if (alertColor[key].some(v => this.executionDesc.includes(v))) {
          color = key;
          break;
        }
      }
      return color;
    }
  },
};
</script>
<style lang="scss" scoped>
$danger: #f56c6c;
$success: #67c23a;
$warning: #ff976a;
$m-theme: #1b82d2;
.flex-btw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.status-undispose {
  color: $danger;
}
.status-disposed {
  color: $success;
}
.campaigns-card {
  padding: 5px 10px;
  border-top: 2px solid #f1f1f1;
  .campaigns-title {
    font-size: 14px;
    line-height: 2;
    color: #333;
  }
  .create-time {
    font-size: 14px;
    color: #888;
    line-height: 2;
  }
  .view-btn {
    text-align: center;
  }
  .danger {
    color: $danger;
  }
  .success {
    color: $success;
  }
  .warning {
    color: $warning;
  }
  .view-adgroup {
    color: $m-theme;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    .adgroups {
      transition: all 0.3s ease;
      &.reverse {
        transform: rotateZ(180deg);
      }
    }
  }
  .van-cell__title {
    overflow: hidden;
  }
  .cust-title {
    width: 100%;
  }
}
</style>