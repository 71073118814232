<template>
  <div>
    <div class="campaigns-card">
      <p class="campaigns-title line-1">{{ info.objName }}</p>
      <div class="create-time flex-btw">
        <span>执行时间:{{ execTime }}</span>
        <span
          v-if="info.detail"
          :class="
            showThis || info.detail.processed
              ? 'status-disposed'
              : 'status-undispose'
          "
        >
          {{ showThis || info.detail.processed ? "已处理" : "未处理" }}
        </span>
      </div>
      <div v-if="info.detail">
        <div class="result" v-if="suggestName(info.detail)['type']">
          <div class="flex-btw">
            <el-tag
              :type="suggestName(info.detail)['type']"
              size="small"
              effect="dark"
              @click="pricingCampaigns(info.detail)"
            >
              {{ suggestName(info.detail)["text"] }}
            </el-tag>
            <el-button
              size="mini"
              type="primary"
              v-if="platform == 'facebook'"
              @click="pricingCampaigns(info.detail)"
              >操作</el-button
            >
          </div>
          <div class="view-btn" v-show="adsets && adsets.length">
            <span class="view-adgroup" @click="viewAdgroups"
              >广告组<van-icon
                name="arrow-down"
                class="adgroups"
                :class="{ reverse: showGroup }"
            /></span>
          </div>
        </div>
        <div v-else>
          <van-cell title="提示">
            <template #label>
              <span
                :class="getColor(info.detail.executionDesc)"
                v-html="executionDesc"
              ></span>
            </template>
          </van-cell>
          <div class="result flex-btw">
            <el-button
              size="mini"
              type="primary"
              v-if="platform == 'facebook'"
              @click="pricingCampaigns(info.detail)"
            >
              查看详情
            </el-button>
            <el-button
              size="mini"
              type="primary"
              v-if="showChangePricingBtn(info.detail)"
              @click="showChangePricing(info.detail)"
            >
              修改预算
            </el-button>
            <el-button
              size="mini"
              type="danger"
              v-if="showChangePricingBtn(info.detail)"
              @click="closeTiktokCampaign(info.detail)"
            >
              关闭系列
            </el-button>
            <el-button
              size="mini"
              type="danger"
              v-if="platform == 'line'"
              @click="switchLineCampaign(info.detail)"
            >
              关闭系列
            </el-button>
            <span
              class="view-adgroup"
              v-show="adsets && adsets.length"
              @click="viewAdgroups"
            >
              广告组<van-icon
                name="arrow-down"
                class="adgroups"
                :class="{ reverse: showGroup }"
              />
            </span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="result flex-btw">
          <van-tag color="#f56c6c" plain size="large"> 参考广告组建议 </van-tag>
          <span class="view-adgroup" @click="viewAdgroups">
            广告组<van-icon
              name="arrow-down"
              class="adgroups"
              :class="{ reverse: showGroup }"
            />
          </span>
        </div>
      </div>
      <el-collapse-transition>
        <div v-show="showGroup && adsets.length">
          <van-cell-group>
            <van-cell
              v-for="item in adsets"
              :key="item.id || item.objId"
              is-link
              @click="pricingCampaigns(item)"
            >
              <template #title>
                <div class="flex-btw">
                  <div class="line-1 cust-title">
                    {{ item.objName }}
                  </div>
                  <span
                    :class="
                      item.processed ? 'status-disposed' : 'status-undispose'
                    "
                  >
                    {{ item.processed ? "已处理" : "未处理" }}
                  </span>
                </div>
              </template>
              <template #label>
                <el-tag
                  v-if="suggestName(item)['type']"
                  :type="suggestName(item)['type']"
                  size="small"
                  effect="dark"
                >
                  {{ suggestName(item)["text"] }}
                </el-tag>
                <span
                  :class="getColor(item.executionDesc)"
                  v-else
                  v-html="formatExcution(item.executionDesc)"
                >
                </span>
                <div v-if="platform == 'tiktok'" class="flex-btw">
                  <el-button
                    size="mini"
                    type="primary"
                    @click.stop="showChangeAdset(item)"
                  >
                    修改预算
                  </el-button>
                  <el-button
                    size="mini"
                    type="danger"
                    @click.stop="closeTiktokAdset(item)"
                  >
                    关闭组
                  </el-button>
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </el-collapse-transition>
    </div>
    <van-popup v-model="showPricing" closeable round position="bottom">
      <change-budget
        :form.sync="form"
        :info="detail"
        @close="closeReport"
        @close-adset="closeAdset"
      ></change-budget>
    </van-popup>
    <van-dialog
      v-model="showDialog"
      title="修改预算"
      show-cancel-button
      @confirm="confirmChangeTiktok"
    >
      <van-cell-group>
        <van-field name="stepper" label="原预算">
          <template #input>
            <span>
              {{ originPricing }}
            </span>
          </template>
        </van-field>
        <van-field name="stepper" label="调整金额">
          <template #input>
            <van-stepper v-model="newPricing" min="0" />
          </template>
        </van-field>
      </van-cell-group>
    </van-dialog>
    <van-dialog
      v-model="showAdsetDialog"
      title="修改广告组预算"
      show-cancel-button
      @confirm="confirmChangeAdset"
    >
      <van-cell-group>
        <van-field name="stepper" label="原预算">
          <template #input>
            <span>
              {{ originPricing }}
            </span>
          </template>
        </van-field>
        <van-field name="stepper" label="调整金额">
          <template #input>
            <van-stepper v-model="newPricing" min="0" />
          </template>
        </van-field>
      </van-cell-group>
    </van-dialog>
  </div>
</template>
<script>
import changeBudget from "./changeBudget";
import closeAds from "./closeAds";
import notification from "./notification";
import {
  pricingInfo,
  changeProcessed,
  getCampaignBudget,
  changeCampaignBudget,
  switchCampaign,
  getAdsetBudget,
  changeAdsetBudget,
  switchAdset,
  switchLineCampaign
} from "@/api/autoRulesResult";
import alertColor from "./alertColor";
export default {
  components: {
    changeBudget,
    closeAds,
    notification,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    accountId: {
      type: [String, Number],
      default: "",
    },
    platform: {
      type: String,
      default: "facebook",
    },
  },
  data() {
    return {
      showGroup: false,
      changeWitch: "changeBudget",
      showPricing: false,
      detail: {},
      form: {},
      showThis: false,
      adsets: [],
      showDialog: false,
      originPricing: 0,
      newPricing: 0,
      tiktokDetail: {},
      showAdsetDialog: false,
      adsetDetail: {},
    };
  },
  computed: {
    execTime() {
      return this.info.detail
        ? this.info.detail.execTime
        : this.info.adsets && this.info.adsets.length
        ? this.info.adsets[0].execTime
        : "--";
    },
    executionDesc() {
      if (!this.info.detail || !this.info.detail.executionDesc) {
        return "";
      }
      if (!this.info.detail.executionDesc.includes("执行明细")) {
        return this.info.detail.executionDesc;
      }
      let text = this.info.detail.executionDesc.substr(
        0,
        this.info.detail.executionDesc.length - 1
      );
      return text
        .replace("(执行明细", "<br/>执行明细")
        .replace(/\-\>/g, "<br/>");
    },
  },
  methods: {
    formatExcution(text) {
      return text
        .replace("(执行明细", "<br/>执行明细")
        .replace(/\-\>/g, "<br/>");
    },
    suggestName(detail) {
      var obj = {
        type: "",
        text: "",
      };
      if (detail.executionType == "PAUSE") {
        obj.text = detail.objType == "campaign" ? "关闭广告系列" : "关闭广告组";
        obj.type = "danger";
      } else if (
        detail.executionType == "CHANGE_BUDGET" ||
        detail.executionType == "CHANGE_CAMPAIGN_BUDGET"
      ) {
        let value = detail.executionSpec.executionOptions[0]["value"];
        value = JSON.parse(value);
        if (value.amount > 0) {
          obj.type = "success";
          obj.text =
            value.unit == "PERCENTAGE"
              ? `建议：预算增加${value.amount}%`
              : `建议：预算增加$${value.amount}`;
        } else if (value.amount < 0) {
          obj.type = "danger";
          obj.text =
            value.unit == "PERCENTAGE"
              ? `建议：预算减少${-value.amount}%`
              : `建议：预算减少$${-value.amount}`;
        } else {
          obj.type = "success";
          obj.text = `建议：在范围内调整预算`;
        }
      }
      return obj;
    },
    pricingCampaigns(detail) {
      if (this.platform != "facebook") return;
      this.detail = detail;
      // if (detail.executionType == "PAUSE") {
      //   this.changeWitch = "closeAds";
      //   this.showPricing = true;
      // } else
      if (detail.executionType == "NOTIFICATION") {
        this.changeProcessed();
      }
      //  else {
      let value = {};
      try {
        value = detail.executionSpec.executionOptions[0]["value"];
        value = JSON.parse(value);
      } catch (error) {}
      // value.limit = [10, 100]
      this.$showLoading();
      this.showPricing = true;
      pricingInfo(
        {
          ids: [detail.objId],
          level: detail.objType,
          accountId: detail.accountId,
        },
        detail.accountId
      )
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0 && res.data.length) {
            this.changeWitch = "changeBudget";
            let data = res.data[0];
            const form = {
              name: detail.objName,
              id: detail.objId,
              level: detail.objType,
              execution: value,
            };
            if (data.daily_budget && !data.lifetime_budget) {
              form.amount = data.daily_budget / 100;
              form.amountType = "daily_budget";
              form.cannotchange = false;
            }
            if (!data.daily_budget && data.lifetime_budget) {
              form.amount = data.lifetime_budget / 100;
              form.amountType = "lifetime_budget";
              form.cannotchange = false;
            }
            if (!data.daily_budget && !data.lifetime_budget) {
              form.amount = 0;
              form.cannotchange = true;
            }
            form.subAmount = this.getAmount(form);
            form.before_amount = data.bid_amount;
            form.before_daily_budget = data.daily_budget;
            form.before_lifetime_budget = data.lifetime_budget;
            this.form = form;
          } else {
            this.$message.error("没有相关数据");
            this.form = {
              amount: 0,
              cannotchange: true,
            };
          }
        })
        .catch((err) => {
          console.log(err);
          this.$hideLoading();
          this.form = {
            amount: 0,
            cannotchange: true,
          };
        });
      // }
    },
    viewAdgroups() {
      this.showGroup = !this.showGroup;
    },
    getAmount(data) {
      if (!data.execution?.amount) {
        return data.amount;
      }
      if (data.execution.unit == "ACCOUNT_CURRENCY") {
        return data.amount + data.execution.amount;
      } else {
        return data.amount + (data.amount * data.execution.amount) / 100;
      }
    },
    closeReport() {
      this.showPricing = false;
      if (!this.detail.processed) {
        this.changeProcessed();
      }
    },
    closeAdset() {
      if (!this.detail.processed) {
        this.changeProcessed();
      }
    },
    changeProcessed() {
      changeProcessed({ ids: this.detail.id }).then((res) => {
        // console.log(res);
        const info = JSON.parse(JSON.stringify(this.info));
        if (res.code == 0) {
          if (this.detail.objType == "campaign") {
            this.showThis = true;
            info.detail.processed = true;
          } else {
            this.adsets = this.adsets.map((v) => {
              if (v.id == this.detail.id) {
                v.processed = true;
              }
              return v;
            });
            info.adsets = this.adsets;
          }
          this.$emit("update-info", info);
        }
      });
    },
    getColor(text) {
      let color = "";
      for (let key in alertColor) {
        if (alertColor[key].some((v) => text.includes(v))) {
          color = key;
          break;
        }
      }
      return color;
    },
    showChangePricingBtn(detail) {
      if (this.platform != "tiktok") return false;
      if (!detail.executionDesc) return false;
      // if (!detail.executionDesc.includes('预算')) return false;
      // if (detail.executionDesc.includes('预算不动')) return false;
      // if (this.getColor(detail.executionDesc) == 'warning') return false;
      return true;
    },
    showChangePricing(detail) {
      const toast = this.$toast.loading({
        duration: 0,
      });
      this.tiktokDetail = detail;
      let data = {
        adAccountId: detail.accountId,
        campaignId: detail.campaignId,
      };
      getCampaignBudget(data)
        .then((res) => {
          this.originPricing = res.data;
          this.newPricing = res.data;
          this.showDialog = true;
          toast.clear();
        })
        .catch((err) => {
          console.log(err);
          toast.clear();
          this.$toast.fail("获取广告系列预算失败");
        });
    },
    confirmChangeTiktok() {
      const toast = this.$toast.loading({
        duration: 0,
      });
      let data = {
        adAccountId: this.tiktokDetail.accountId,
        campaignId: this.tiktokDetail.campaignId,
        adjust: this.newPricing - this.originPricing,
      };
      changeCampaignBudget(data)
        .then((res) => {
          toast.clear();
          this.$toast.success("修改成功");
          this.detail = this.tiktokDetail;
          this.changeProcessed();
        })
        .catch((err) => {
          console.log(err);
          toast.clear();
          this.$toast.fail(err.message || "修改失败");
        });
    },
    closeTiktokCampaign(detail) {
      const data = {
        adAccountId: detail.accountId,
        campaignId: detail.campaignId,
        optStatus: "DISABLE",
      };
      this.$dialog
        .confirm({
          title: "关闭系列",
          message: "确定要关闭广告系列吗?",
        })
        .then(() => {
          const toast = this.$toast.loading({
            duration: 0,
          });
          switchCampaign(data)
            .then((res) => {
              toast.clear();
              this.$toast.success("关闭广告系列成功");
              this.detail = detail;
              this.changeProcessed();
            })
            .catch((err) => {
              toast.clear();
              this.$toast.fail(err.message || "关闭失败");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    showChangeAdset(item) {
      const toast = this.$toast.loading({
        duration: 0,
      });
      this.tiktokDetail = item;
      let data = {
        adAccountId: item.accountId,
        adGroupId: item.adsetId,
      };
      getAdsetBudget(data)
        .then((res) => {
          this.originPricing = res.data;
          this.newPricing = res.data;
          this.showAdsetDialog = true;
          toast.clear();
        })
        .catch((err) => {
          console.log(err);
          toast.clear();
          this.$toast.fail("获取广告组预算失败");
        });
    },
    confirmChangeAdset() {
      const toast = this.$toast.loading({
        duration: 0,
      });
      let data = {
        adAccountId: this.tiktokDetail.accountId,
        adGroupId: this.tiktokDetail.adsetId,
        adjust: this.newPricing - this.originPricing,
      };
      changeAdsetBudget(data)
        .then((res) => {
          toast.clear();
          this.$toast.success("修改成功");
          this.detail = this.tiktokDetail;
          this.changeProcessed();
        })
        .catch((err) => {
          console.log(err);
          toast.clear();
          this.$toast.fail(err.message || "修改失败");
        });
    },
    closeTiktokAdset(detail) {
      const data = {
        adAccountId: detail.accountId,
        adGroupId: detail.adsetId,
        optStatus: "DISABLE",
      };
      this.$dialog
        .confirm({
          title: "关闭广告组",
          message: "确定要关闭此广告组吗?",
        })
        .then(() => {
          const toast = this.$toast.loading({
            duration: 0,
          });
          switchAdset(data)
            .then((res) => {
              toast.clear();
              this.$toast.success("关闭广告组成功");
              this.detail = detail;
              this.changeProcessed();
            })
            .catch((err) => {
              toast.clear();
              this.$toast.fail(err.message || "关闭失败");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    switchLineCampaign(detail) {
      const data = {
        adAccountId: detail.accountId,
        campaignId: detail.campaignId,
        status: "PAUSED",
      };
      this.$dialog
        .confirm({
          title: "关闭系列",
          message: "确定要关闭广告系列吗?",
        })
        .then(() => {
          const toast = this.$toast.loading({
            duration: 0,
          });
          switchLineCampaign(data)
            .then((res) => {
              toast.clear();
              this.$toast.success("关闭广告系列成功");
              this.detail = detail;
              this.changeProcessed();
            })
            .catch((err) => {
              toast.clear();
              this.$toast.fail(err.message || "关闭失败");
            });
        })
        .catch(() => {
          // on cancel
        });
    }
  },
  mounted() {
    this.adsets = this.info.adsets;
  },
};
</script>
<style lang="scss" scoped>
$danger: #f56c6c;
$success: #67c23a;
$warning: #ff976a;
$m-theme: #1b82d2;
.flex-btw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.status-undispose {
  color: $danger;
  flex-shrink: 0;
}
.status-disposed {
  color: $success;
  flex-shrink: 0;
}
.campaigns-card {
  padding: 5px 10px;
  border-top: 2px solid #f1f1f1;
  .result {
    padding-top: 4px;
  }
  .campaigns-title {
    font-size: 14px;
    line-height: 2;
    color: #333;
  }
  .create-time {
    font-size: 14px;
    color: #888;
    line-height: 2;
  }
  .view-btn {
    text-align: center;
  }
  .danger {
    color: $danger;
  }
  .success {
    color: $success;
  }
  .warning {
    color: $warning;
  }
  .view-adgroup {
    color: $m-theme;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    .adgroups {
      transition: all 0.3s ease;
      &.reverse {
        transform: rotateZ(180deg);
      }
    }
  }
  .van-cell__title {
    overflow: hidden;
  }
  .cust-title {
    flex: 1;
    padding-right: 8px;
  }
}
</style>