<template>
  <div class="account-card min-shadow">
    <div class="card-header">
      <p class="account-title line-1">
        {{ info.objName }}
      </p>
      <p class="add-time line-1">采集时间:{{ adDataPullTime }}</p>
    </div>
    <div class="card-body">
      <account-detail :detail="info" />
      <template v-for="(item, i) in info.campaigns">
        <campaigns-card
          :key="item.id || item.objId"
          :info="item"
          :account-id="info.objId"
          :platform="info.platform"
          @update-info="updateInfo($event, i)"
        ></campaigns-card>
      </template>
    </div>
  </div>
</template>
<script>
import campaignsCard from "./campaignsCard";
import accountDetail from "./accountDetail.vue";
export default {
  components: {
    campaignsCard,
    accountDetail
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    adDataPullTime() {
      if (this.info.adDataPullTime) return this.info.adDataPullTime
      if (!this.info.campaigns || !this.info.campaigns.length) {
        return "--";
      }
      let item = this.info.campaigns[0];
      return item.detail
        ? item.detail.adDataPullTime
        : item.adsets && item.adsets.length
        ? item.adsets[0].adDataPullTime
        : "--";
    },
  },
  methods: {
    updateInfo(item, i) {
      const info = JSON.parse(JSON.stringify(this.info));
      info.campaigns[i] = item;
      this.$emit("update-info", info);
    },
  },
};
</script>
<style lang="scss" scoped>
.account-card {
  width: 100%;
  background: #fff;
  margin: 5px 0;
  .card-header {
    // display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    word-break: break-all;
    line-height: 2;
    // border-bottom: 2px solid #f1f1f1;
    font-size: 13px;
    padding: 0 10px;
    p {
      flex: 1;
      &.add-time {
        // padding-left: 8px;
        // text-align: right;
        color: #888;
        font-size: 12px;
      }
    }
  }
}
.line-2 {
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box; // 弹性伸缩盒
  -webkit-box-orient: vertical; // 设置伸缩盒子元素排列方式
}
</style>