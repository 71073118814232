export default {
    'success': [
        '提示 复制组 / 组预算',
        '提示复制组',
        '提示复制组',
        '预算 +100%, 提示扩10个广告组',
        '预算 +100%, 提示扩6-10个广告组',
        '预算 +100%, 提示扩组',
        '预算 +100%, 同时提示扩组，并设定预算上限100',
        '预算 +100%, 同时提示扩组，并设定预算上限200',
        '预算 +150%, 提示扩组',
        '预算 +200%, 提示扩组',
        '预算 +30%, 提示扩组',
        '预算 +40%, 提示扩组',
        '预算 +50%, 提示扩3-4个广告组',
        '预算 +50%, 提示扩4-5个广告组',
        '预算 +50%, 提示扩组',
        '预算 +60%, 提示扩组',
        '预算 +70%, 提示扩组',
        '预算 +75%, 提示扩组',
        '预算 +80%, 提示扩组',
        '预算+10',
        '预算+100',
        '预算+20',
        '预算+25 提示扩组',
        '预算+30',
        '预算+40',
        '预算+50',
        '预算+60',
        '预算+70 提示扩组',
        '预算+75 提示扩组',
        '预算+80',
    ],
    'danger': [
        '关闭系列',
        '关组',
        '提示关组 同时提示换受众',
        '提示换组，换素材',
        '提示降预算',
        '预算 -20%,提示换受众',
        '预算 -30%,提示换受众',
        '预算 -40%,提示换受众',
        '预算 -50%,提示换受众',
        '预算-30%',
        '预算不动，提示换受众'
    ],
    'warning': [
        '当前预算超过200刀,建议手动重点调整',
        '设置系列预算上限20刀',
        '设置组花费上限15',
        '设置组预算上限20刀',
        '设置组预算上限30刀',
        '设置组预算上限40刀',
        '设置组预算上限50刀',
        '提示改价、改网站等',
    ]
}