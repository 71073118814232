import reportData from "./reportData";
import {
    getReport
} from "@/api/autoRulesResult";

export default {
    components: {
        reportData
    },
    props: {
        info: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            title: "",
            suggest: "",
            showDate: "today",
            detail: {},
            adsets: []
        }
    },
    methods: {
        getDetail(opt) { 
            var date_start = this.getDate(this.dataList(this.showDate));
            this.$showLoading();
            var data = {
                level: opt.objType,
                page_length: 1,
                date_start: date_start,
                date_end: this.showDate == 'yesterday' ? date_start : this.getDate(),
                filtering: [],
                account_ids: [opt.accountId],
                keyword: opt.objId,
                fields: [
                    "id",
                    "unique_clicks",
                    "ctr",
                    "purchase",
                    "purchase_cost",
                    "purchase_roas",
                    "purchase_rate",
                    "add_to_cart",
                    "add_to_cart_cost",
                    "adProductReport",
                    "add_payment_info",
                    "add_payment_info_cost",
                    "spend",
                    "cpc",
                    "budget",
                    "cpm",
                    "link_click",
                    "inline_link_click_ctr",
                    "cost_per_inline_link_click",
                    "local_order"
                ],
                action_attribution_windows: [],
                object_filtering: [],
                sort: [],
            };
            getReport(data, opt.accountId).then((res) => {
                this.$hideLoading();
                this.detail = res?.data?.data[0] || {};
                if (opt.objType == "campaign") {
                    this.getAdsetRepotList(opt)
                }
            });
        },
        getAdsetRepotList(opt) {
            var date_start = this.getDate(this.dataList(this.showDate));
            var data = {
                level: "adset",
                page_length: 100,
                date_start: date_start,
                date_end: this.showDate == 'yesterday' ? date_start : this.getDate(),
                filtering: [
                    {
                    field: "spend",
                    operator: "GREATER_THAN",
                    value: 0
                }
            ],
                account_ids: [opt.accountId],
                keyword: null,
                fields: [
                    "id",
                    "adset_name",
                    "unique_clicks",
                    "ctr",
                    "purchase",
                    "purchase_cost",
                    "purchase_roas",
                    "purchase_rate",
                    "add_to_cart",
                    "add_to_cart_cost",
                    "adProductReport",
                    "add_payment_info",
                    "add_payment_info_cost",
                    "spend",
                    "cpc",
                    "status"
                ],
                action_attribution_windows: [],
                object_filtering: [{
                        field: "campaign.id",
                        operator: "IN",
                        value: [opt.objId]
                    },
                    {
                        field: "status",
                        operator: "IN",
                        value: [1]
                    }
                ],
                sort: [],
            };
            getReport(data, opt.accountId).then((res) => {
                // console.log(res);
                if (res.code == 0) {
                    this.adsets = res.data.data;
                    this.adsets.map(v => {
                        if (v.status == 'PAUSED') {
                            v.active = false
                            v.disabled = false
                        } else if (v.status == 'ACTIVE') {
                            v.active = true
                            v.disabled = false
                        } else {
                            v.disabled = true
                            v.active = true
                        }
                    })
                    // this.adsets = [{adset_name:"123dskjfhkj"},{adset_name:"123dskjfhkj"}]
                }
            });
        },
        changeDate(e) {
            this.showDate = e.id;
            this.getDetail(this.info);
        },
        getDate(time) {
            var date = time ? new Date(time) : new Date();
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            month = month < 10 ? "0" + month : month;
            var day = date.getDate();
            day = day < 10 ? "0" + day : day;
            return `${year}-${month}-${day}`;
        },
        dataList(dateType) {
            var date = new Date();
            return {
                today: () => {
                    return date;
                },
                yesterday: () => {
                    date.setDate(date.getDate() - 1);
                    return date;
                },
                threeday: () => {
                    date.setDate(date.getDate() - 3);
                    return date;
                },
                sevenday: () => {
                    date.setDate(date.getDate() - 7);
                    return date;
                },
                currentmonth: () => {
                    date.setDate(1);
                    return date;
                },
            } [dateType]();
        },
    }
}